window.openContactUsModal = function openContactUsModal() {
    let toggle = document.getElementById('contact-us-modal-trigger');
    let anchorsModal = document.getElementById('anchors-modal');

    if (!!anchorsModal) {
        anchorsModal.style.display = "none";
    }

    if (toggle != null) {
        toggle.click();
    }
}

window.openLocationModal = openLocationModal;

function openLocationModal() {
    let toggle = document.getElementById('location-modal-trigger');

    if (toggle != null) {
        toggle.click();
    }
}

window.openDiscountsModal = openDiscountsModal;

function openDiscountsModal() {
    let toggle = document.getElementById('discounts-modal-trigger');

    if (toggle != null) {
        toggle.click();
    }
}

window.openAnchorsModal = openAnchorsModal;

function openAnchorsModal() {
    let toggle = document.getElementById('anchors-modal-trigger');
    if (toggle != null) {
        toggle.click();
    }
}

window.openRoutingModal = openRoutingModal;

function openRoutingModal(bookingId, medtechId, timeStart, timeEnd) {
    let toggle = document.getElementById('routing-modal-trigger');
    let bookingIdInput = document.getElementById('routing_booking_id');
    let medtechIdInput = document.getElementById('routing_medtech_id');
    let timeStartInput = document.getElementById('routing_time_start');
    let timeEndInput = document.getElementById('routing_time_end');

    if (toggle != null) {
        bookingIdInput.value = bookingId;
        const medtechs = Array.from(medtechIdInput.options);
        medtechs.forEach((option, i) => {
            if (option.value === medtechId) {
                medtechIdInput.selectedIndex = i;
                option.setAttribute('selected', 'selected')
            } else {
                medtechIdInput.selectedIndex = i;
                option.removeAttribute('selected')
            }
        });
        timeStartInput.setAttribute('value', timeStart);
        timeEndInput.setAttribute('value', timeEnd);

        toggle.click();
    }
}

window.closeModal = closeModal;

function closeModal() {
    for (const toggle of document.getElementsByClassName('modal-dismiss-btn')) {
        if (toggle != null) {
            toggle.click();
        }
    }
}