window.onload = () => {
  if (localStorage.getItem('cartStatus') == 'open') {
      let cart = document.querySelector("div#cart");
      let cartBadge = document.querySelector("p#cart-badge");
      let cartIcon = document.querySelector("i#cart-btn-icon");
      
      if (!!cart) {
        cart.classList.remove("pop-in");
        cart.classList.remove("pop-out");
        cart.classList.remove('hidden');
      }

      if (!!cartIcon) {
        cartIcon.classList.remove("fa-shopping-cart");
        cartIcon.classList.add("fa-times");
      }

      if (!!cartBadge) cartBadge.classList.add("hidden");
  }
}
