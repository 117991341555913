import autocomplete from 'js-autocomplete';

window.initMap = function initMap(address, lat, lng) {
    const geocoder = new google.maps.Geocoder();
    const infowindow = new google.maps.InfoWindow();
    const locationButton = document.createElement("button");
    const locationInput = document.querySelectorAll('input.location-input')[0];
    const searchButton = document.getElementById("location-form-search");

    // document.querySelector('.location-service-autocomplete-results').innerHTML = '';
    // document.querySelector('.location-service-autocomplete-results').style.display = 'none';
    document.querySelectorAll("[class$='autocomplete-results']")[0].innerHTML = '';
    document.querySelectorAll("[class$='autocomplete-results']")[0].style.display = 'none';

    // Initialize Map
    let myCoords = new google.maps.LatLng(25.775163, -80.208615);
    let mapOptions = {
        center: myCoords,
        zoom: 17,
        mapTypeId: google.maps.MapTypeId.ROADMAP,
        mapTypeControl: false,
        fullscreenControl: false,
        streetViewControl: false
    };
    let map = new google.maps.Map(document.getElementById('map'), mapOptions);

    if ( address.trim().length !== 0 ) {
        //after map initialization, center map to location address field
        reverseGeocodeAddress(geocoder, map, address);
    } else if(lat !== undefined && lng !== undefined ){
        //center to coordinates if it is provided
        myCoords = new google.maps.LatLng(lat, lng);
        map.setCenter(myCoords);
    }


    //map marker initialize
    let marker = new google.maps.Marker({
        position: myCoords,
        map: map,
    });

    map.addListener('center_changed', () => {
        marker.setPosition(map.getCenter());

    });

    //geocode address for every map movement
    google.maps.event.addListener(map, 'dragend', function() {
        geocodeLatLng(geocoder, map, infowindow);

    }, { passive: true });

    //search functionality using google geocoding api
    searchButton.setAttribute("remote", true);
    searchButton.addEventListener("click", () => {
        reverseGeocodeAddress(geocoder, map, document.getElementById("location").value);
        //clear google place search result
        // document.querySelector('.autocomplete-results').innerHTML = '';
        // document.querySelector('.autocomplete-results').style.display = 'none';
        document.querySelectorAll("[class$='autocomplete-results']")[0].innerHTML = '';
        document.querySelectorAll("[class$='autocomplete-results']")[0].style.display = 'none';
    });

    //Current location functionality
    locationButton.textContent = "Go to Current Location";
    locationButton.classList.add("custom-map-control-button");
    locationButton.setAttribute('type', 'button');

    map.controls[google.maps.ControlPosition.TOP_CENTER].push(locationButton);
    locationButton.addEventListener("click", () => {
        // Try HTML5 geolocation.
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(
                (position) => {
                    const pos = {
                        lat: position.coords.latitude,
                        lng: position.coords.longitude,
                    };
                    map.setCenter(pos);
                    geocodeLatLng(geocoder, map, infowindow);
                    map.setZoom(17);
                    setCoordinatesToFields(map);
                },

                //geolocation service error e.g. permission blocked
                handleLocationError
            );
        } else {
            // Browser doesn't support Geolocation
            handleLocationError
        }
    });

    let autocomplete = new google.maps.places.Autocomplete(locationInput);
    autocomplete.setComponentRestrictions({
        country: ["us"],
    });

    google.maps.event.addListener(autocomplete, 'place_changed', function() {
        if (!!searchButton) {
            searchButton.click();
        }
    });
}

window.initMapMobile = function initMapMobile(address, lat, lng) {
    const geocoder = new google.maps.Geocoder();
    const infowindow = new google.maps.InfoWindow();
    const locationButton = document.createElement("button");
    const locationInput = document.querySelectorAll('input.location-input')[1];
    const searchButton = document.getElementById("location-form-search-mobile");

    // document.querySelector('.location-service-autocomplete-results-mobile').innerHTML = '';
    // document.querySelector('.location-service-autocomplete-results-mobile').style.display = 'none';
    document.querySelectorAll("[class$='autocomplete-results-mobile']")[0].innerHTML = '';
    document.querySelectorAll("[class$='autocomplete-results-mobile']")[0].style.display = 'none';

    // Initialize Map
    let myCoords = new google.maps.LatLng(25.775163, -80.208615);
    let mapOptions = {
        center: myCoords,
        zoom: 17,
        mapTypeId: google.maps.MapTypeId.ROADMAP,
        mapTypeControl: false,
        fullscreenControl: false,
        streetViewControl: false
    };
    let map = new google.maps.Map(document.getElementById('map-mobile'), mapOptions);

    if (lat === undefined || lng === undefined) {
        //after map initialization, center map to location address field
        reverseGeocodeAddress(geocoder, map, address);
    } else {
        //center to coordinates if it is provided
        myCoords = new google.maps.LatLng(lat, lng);
        map.setCenter(myCoords);
    }


    //map marker initialize
    let marker = new google.maps.Marker({
        position: myCoords,
        map: map,
    });

    map.addListener('center_changed', () => {
        marker.setPosition(map.getCenter());

    });

    //geocode address for every map movement
    google.maps.event.addListener(map, 'dragend', function() {
        geocodeLatLng(geocoder, map, infowindow);

    }, { passive: true });

    //search functionality using google geocoding api
    searchButton.setAttribute("remote", true);
    searchButton.addEventListener("click", () => {
        reverseGeocodeAddress(geocoder, map, document.getElementById("location").value);
        //clear google place search result
        // document.querySelector('.autocomplete-results').innerHTML = '';
        // document.querySelector('.autocomplete-results').style.display = 'none';
        document.querySelectorAll("[class$='autocomplete-results-mobile']")[0].innerHTML = '';
        document.querySelectorAll("[class$='autocomplete-results-mobile']")[0].style.display = 'none';
    });

    //Current location functionality
    locationButton.textContent = "Go to Current Location";
    locationButton.classList.add("custom-map-control-button");
    locationButton.setAttribute('type', 'button');

    map.controls[google.maps.ControlPosition.TOP_CENTER].push(locationButton);
    locationButton.addEventListener("click", () => {
        // Try HTML5 geolocation.
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(
                (position) => {
                    const pos = {
                        lat: position.coords.latitude,
                        lng: position.coords.longitude,
                    };
                    map.setCenter(pos);
                    geocodeLatLng(geocoder, map, infowindow);
                    map.setZoom(17);
                    setCoordinatesToFields(map);
                },

                //geolocation service error e.g. permission blocked
                handleLocationError
            );
        } else {
            // Browser doesn't support Geolocation
            handleLocationError
        }
    });

    let autocomplete = new google.maps.places.Autocomplete(locationInput);
    autocomplete.setComponentRestrictions({
        country: ["us"],
    });

    google.maps.event.addListener(autocomplete, 'place_changed', function() {
        if (!!searchButton) {
            searchButton.click();
        }
    });
}

function handleLocationError(err) {
    console.warn(err.message);
    createNotice(err.message);
}

function geocodeLatLng(geocoder, map, infowindow) {
    const latlng = {
        lat: map.getCenter().lat(),
        lng: map.getCenter().lng(),
    };

    geocoder
        .geocode({ location: latlng })
        .then((response) => {
            if (response.results[0]) {
                map.setZoom(17);
                document.getElementById('location').value = response.results[0].formatted_address;
                setCoordinatesToFields(map);
                $.ajax({
                    type: "GET",
                    url: "/area/check",
                    dataType: "json",
                    data: { address: document.getElementById("location").value }
                });

            } else {
                createNotice("Location Search: No results found.");
            }
        })
}

function reverseGeocodeAddress(geocoder, map, address) {
    geocoder
        .geocode({ address: address })
        .then((response) => {
            if (response.results[0]) {
                document.getElementById('location').value = response.results[0].formatted_address;

                const coordinates = {
                    lat: response.results[0].geometry.location.lat(),
                    lng: response.results[0].geometry.location.lng(),
                };
                map.setCenter(coordinates);
                setCoordinatesToFields(map);

                $.ajax({
                    type: "GET",
                    url: "/area/check",
                    dataType: "json",
                    data: { address: document.getElementById("location").value }
                });

            } else {
                createNotice("Location Search: No results found.");
            }
        })
}

window.initMap2 = function initMap2(lat, lng) {
    var myCoords = new google.maps.LatLng(lat, lng);
    var mapOptions = {
        center: myCoords,
        zoom: 14
    };
    var map = new google.maps.Map(document.getElementById('map'), mapOptions);
    setCoordinatesToFields(map);
}

function setCoordinatesToFields(map) {
    let lat_field = document.querySelectorAll("[id$=latitude]")[0];
    let lng_field = document.querySelectorAll("[id$=longitude]")[0];
    if (document.body.contains(lat_field) && document.body.contains(lng_field)) {
        lat_field.value = map.getCenter().lat();
        lng_field.value = map.getCenter().lng();
    }
}