import { Controller } from "stimulus";

export default class extends Controller {
  static values = {
    addUrl: String,
    updateUrl: String,
    removeUrl: String,
    redirectUrl: String,
    price: String,
    type: String,
  };
  static targets = ["product", "quantity"];

  toggleMobileFee() {
    let mobileFeeButton = document.querySelector(".toggle-mobile-fee");
    let mobileFeeValue = document.querySelector(".mobile-fee-value");
    if (mobileFeeButton.innerText == "Remove") {
      mobileFeeButton.innerText = "Add";
      mobileFeeValue.innerText = "$ 0.00";
      localStorage.setItem("withMobileFee", "false");
    } else {
      mobileFeeButton.innerText = "Remove";
      mobileFeeValue.innerText = "$ 130.00";
      localStorage.setItem("withMobileFee", "true");
    }
  }

  addToCart(e) {
    e.preventDefault();
    let url = this.addUrlValue;
    fetch(url)
      .then(() => {
        createNotice("Item has been added to your cart.");
        location.replace(this.redirectUrlValue);
      })
      .catch(() => {
        location.replace(this.redirectUrlValue);
        createNotice("Please check your internet connection and try again.");
      });
  }

  removeFromCart(e) {
    e.preventDefault();
    let url = this.removeUrlValue;
    fetch(url)
      .then(() => {
        this.productTarget.remove();
        this.updateTotal("decrease", this.quantityTarget.value);
        this.updateCartBadge("decrease", this.quantityTarget.value);
        this.updateCounter();
        location.reload();
      })
      .catch(() => {
        location.replace(this.redirectUrlValue);
        createNotice("Please check your internet connection and try again.");
      });
  }

  increaseQuantity(e) {
    e.preventDefault();
    let newQuantity = parseInt(this.quantityTarget.value) + 1;
    let url = `${this.updateUrlValue}&quantity=${newQuantity}`;
    fetch(url)
      .then(() => {
        this.quantityTarget.value = parseInt(this.quantityTarget.value) + 1;
        this.updateTotal("increase");
        this.updateCartBadge("increase");
      })
      .catch(() => {
        location.replace(this.redirectUrlValue);
        createNotice("Please check your internet connection and try again.");
      });
  }

  decreaseQuantity(e) {
    e.preventDefault();
    if (this.quantityTarget.value > 1) {
      let newQuantity = parseInt(this.quantityTarget.value) - 1;
      let url = `${this.updateUrlValue}&quantity=${newQuantity}`;
      fetch(url)
        .then(() => {
          this.quantityTarget.value = parseInt(this.quantityTarget.value) - 1;
          this.updateTotal("decrease");
          this.updateCartBadge("decrease");
        })
        .catch(() => {
          location.replace(this.redirectUrlValue);
          createNotice("Please check your internet connection and try again.");
        });
    } else {
      this.removeFromCart(e);
    }
  }

  updateQuantity() {
    let url = `${this.updateUrlValue}&quantity=${this.quantityTarget.value}`;
    fetch(url)
      .then(() => {
        location.replace(this.redirectUrlValue);
      })
      .catch(() => {
        location.replace(this.redirectUrlValue);
        createNotice("Please check your internet connection and try again.");
      });
  }

  updateTotal(action, step = 1) {
    let price = parseFloat(this.priceValue) * parseFloat(step);
    let total = document.querySelector("#booking-total .value");
    let currentTotal = total.innerText.split("$ ")[1].replace(/,/g, "");
    let newTotal = 0.0;
    if (action == "increase") {
      newTotal = parseFloat(currentTotal) + parseFloat(price);
    } else {
      newTotal = parseFloat(currentTotal) - parseFloat(price);
    }
    total.innerText = `$ ${parseFloat(newTotal)
      .toFixed(2)
      .replace(/\d(?=(\d{3})+\.)/g, "$&,")}`;
  }

  updateCartBadge(action, step = 1) {
    let cartBadge = document.getElementById("cart-badge");
    let currentBadge = cartBadge.innerText;
    let newBadge = 0;

    if (parseInt(currentBadge) > 0) {
      if (action == "increase") {
        newBadge = parseInt(currentBadge) + step;
      } else {
        newBadge = parseInt(currentBadge) - step;
      }
      cartBadge.innerText = newBadge;
    }

    if (parseInt(newBadge) == 0) {
      cartBadge.remove();
    }
  }

  updateCounter() {
    let counter = document.querySelector("#cart .item-count");
    let currentCount = counter.innerText.split(" tests");
    let newCount = 0;

    if (parseInt(currentCount) > 0) {
      newCount = parseInt(currentCount) - 1;

      if (parseInt(currentCount) == 1) {
        this.resetCart();
      }
    }

    counter.innerText = `${newCount} tests`;
  }

  resetCart() {
    let container = document.getElementById("cart-container");
    container.innerHTML = `
            <div id="empty-cart">
                <i class="fal fa-inbox"></i>
                <p id="cart-prompt">Start by adding tests to your cart...</p>
            </div>
        `;

    let totalTime = document.querySelector("#total-time .value");
    totalTime.innerText = "~less than a minute";

    let serviceFee = document.querySelector("#service-fee .value");
    serviceFee.innerText = "$ 0.00";

    let bookingTotal = document.querySelector("#booking-total .value");
    bookingTotal.innerText = "$ 0.00";
  }
}
