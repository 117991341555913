import Carousel from "stimulus-carousel"

export default class extends Carousel {
    connect() {
        super.connect()
        this.swiper
        this.defaultOptions
    }

    get defaultOptions() {
        return {
            "slidesPerView": 1.5,
            "centeredSlides": true,
            "spaceBetween": 10,
            "initialSlide": 1,
            "navigation": {
                "nextEl": '.swiper-button-next',
                "prevEl": '.swiper-button-prev'
            }
        }
    }
}