import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["input", "product"];

  connect() {
    this.countBox();
    this.toggleVisibility();
    let cart = addTestsToCart();
    triggerPriceCalculator(
      cart,
      document.getElementById("booking_discount")?.value,
      document.getElementById("booking_customer_id")?.value,
      document.getElementById("booking_service_area")?.options[
        document.getElementById("booking_service_area").selectedIndex
      ].text
    );
  }

  search(event) {
    let lowerCaseSearchTerm = this.inputTarget.value.toLowerCase();

    this.productTargets.forEach((element, i) => {
      let productKey = element.getAttribute("data-product-key");

      element.classList.toggle(
        "hidden",
        !productKey.includes(lowerCaseSearchTerm)
      );
    });
  }

  changeState(event) {
    let checkbox_value = event.srcElement.value;
    if (event.srcElement.checked === true) {
      document.querySelector("#product_quantity_" + checkbox_value).value = "1";
      document.querySelector(
        "#product_quantity_" + checkbox_value
      ).disabled = false;
    } else {
      document.querySelector("#product_quantity_" + checkbox_value).value = "0";
      document.querySelector(
        "#product_quantity_" + checkbox_value
      ).disabled = true;
    }
    this.countBox();
    let cart = addTestsToCart();
    triggerPriceCalculator(
      cart,
      document.getElementById("booking_discount")?.value,
      document.getElementById("booking_customer_id")?.value,
      document.getElementById("booking_service_area")?.options[
        document.getElementById("booking_service_area").selectedIndex
      ].text
    );
  }

  countBox() {
    checkedtotal = countCheckedBoxes(
      document.getElementById("available-tests").parentElement
    );
    document.querySelector("#tests-selected-count").textContent = checkedtotal;
  }

  toggleVisibility() {
    let search = document.getElementById("admin-checkboxes-search");
    let checkboxes = document.querySelectorAll(".admin-checkbox");
    for (i = 0; i < checkboxes.length; i++) {
      if (checkboxes[i].style.display === "none") {
        checkboxes[i].style.display = "flex";
      } else {
        checkboxes[i].style.display = "none";
      }
    }
    search.classList.toggle("hidden");
  }

  subQty(event) {
    let id = event.target.dataset.testId;
    if (document.querySelector("#product_" + id).checked) {
      let test_qty = document.querySelector("#product_quantity_" + id);
      if (parseInt(test_qty.value) > 1) {
        test_qty.value = parseInt(test_qty.value) - 1;
      } else if (parseInt(test_qty.value) == 1) {
        test_qty.value = parseInt(test_qty.value) - 1;
        document.querySelector("#product_" + id).checked = false;
        this.countBox();
      } else {
        document.querySelector("#product_" + id).checked = false;
      }
    }
    this.countBox();
    let cart = addTestsToCart();
    triggerPriceCalculator(
      cart,
      document.getElementById("booking_discount")?.value,
      document.getElementById("booking_customer_id")?.value,
      document.getElementById("booking_service_area")?.options[
        document.getElementById("booking_service_area").selectedIndex
      ].text
    );
  }

  addQty(event) {
    let id = event.target.dataset.testId;
    if (document.querySelector("#product_" + id).checked === false) {
      document.querySelector("#product_" + id).checked = true;
    }
    let test_qty = document.querySelector("#product_quantity_" + id);
    if (parseInt(test_qty.value) < 100) {
      test_qty.value = parseInt(test_qty.value) + 1;
    }

    this.countBox();
    let cart = addTestsToCart();
    triggerPriceCalculator(
      cart,
      document.getElementById("booking_discount")?.value,
      document.getElementById("booking_customer_id")?.value,
      document.getElementById("booking_service_area")?.options[
        document.getElementById("booking_service_area").selectedIndex
      ].text
    );
  }
}
