import { Controller } from "stimulus";
import Splide from '@splidejs/splide';

export default class extends Controller {
    static targets = ["splide"];
    static values = { isCategorySplide: Boolean, focus: Number };

    connect() {
        if (this.isCategorySplideValue) {
            new Splide(this.splideTarget, {
                perPage: 5,
                perMove: 1,
                rewind: true,
                start: this.focusValue,
                gap: '12px',
                breakpoints: {
                    924: {
                        perPage: 4
                    },
                    724: {
                        perPage: 3
                    },
                    524: {
                        perPage: 2
                    }
                },
            }).mount();
        } else {
            new Splide(this.splideTarget, {
                perPage: 1,
                perMove: 1,
                rewind: true,
            }).mount();
        }
    }
}
