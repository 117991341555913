import { DateTime, Settings } from "luxon";

/* Luxon Settings */
Settings.defaultZone = "America/New_York";
DateTime.now().setZone("default") // uses the default time
// DateTime.now().setZone("system") // uses the user's system time

window.createCalendar = (datesToShow = [], dateAsToday = DateTime.now()) => {
  let daysPerSlide = 3;
  if (window.innerWidth < 721) {
    daysPerSlide = 2;
  }

  window.addEventListener('resize', (event) => {
    if (window.innerWidth < 721) {
      daysPerSlide = 2;
    } else {
      daysPerSlide = 3;
    }
  });

  //empty slots for all medtech
  let slotsAvailable = true;
  if(datesToShow.length <= 0){
    datesToShow.push(DateTime.now());
    slotsAvailable = false;
  }

  const date = DateTime.now();
  if(Object.prototype.toString.call(dateAsToday) === '[object Date]'){
    dateAsToday = DateTime.fromJSDate(dateAsToday,{ zone: "default" });
  }
  let firstDayOfWeekDate = datesToShow[0].minus({ days: 1 });
  let displayedDate = date.set({year: firstDayOfWeekDate.year, month: firstDayOfWeekDate.month, day: (firstDayOfWeekDate.day - 1)});
  let selectedDate = date.set({year: firstDayOfWeekDate.year, month: firstDayOfWeekDate.month, day: (firstDayOfWeekDate.day + 1)});

  const mappedDatesToShow = datesToShow.map((date) => date.toFormat('yyyy-MM-dd'));
  const renderCalendar = (mappedDatesToShow) => {
    
    const monthDays = document.querySelector(".days");

    let days = "";
    for (let i = 0; i <= (daysPerSlide - 1); i++) {
      displayedDate = displayedDate.plus({days:1});

      if (
        slotsAvailable &&
        displayedDate.day === selectedDate.day &&
        displayedDate.month === selectedDate.month &&
        displayedDate.year === selectedDate.year &&
        mappedDatesToShow.includes(displayedDate.toFormat('yyyy-MM-dd'))
      ) {
        days += `<div class="day card dark-shadow slide-${i} active" id="D${displayedDate.toMillis()}"><div class="date-value">${displayedDate.monthLong} ${displayedDate.day}</div><div class="day-value">${displayedDate.weekdayLong}</div></div>`;
      } else if (mappedDatesToShow.includes(displayedDate.toFormat('yyyy-MM-dd')) && slotsAvailable) {
        days += `<div class="day card dark-shadow slide-${i}" id="D${displayedDate.toMillis()}"><div class="date-value">${displayedDate.monthLong} ${displayedDate.day}</div><div class="day-value">${displayedDate.weekdayLong}</div></div>`;
      } else {
        days += `<div class="day card dark-shadow slide-${i} disabled"><div class="date-value">${displayedDate.monthLong} ${displayedDate.day}</div><div class="day-value">${displayedDate.weekdayLong}</div></div>`;
      }
    }
    
    monthDays.innerHTML = days;

    if (!!document.querySelector(".slide-0")) {
      document.querySelector(".slide-0").addEventListener("click", (event) => {
        if (event.currentTarget.id !==  "") {
          selectedDate = DateTime.fromMillis(Number(event.currentTarget.id.split("D")[1]));
          displayedDate = displayedDate.set({year: selectedDate.year, month: selectedDate.month, day: (selectedDate.day-(daysPerSlide - 1))});
          renderCalendar(mappedDatesToShow);
        }
      });
    }

    if (!!document.querySelector(".slide-1")) {
      document.querySelector(".slide-1").addEventListener("click", (event) => {
        if (event.currentTarget.id !==  "") {
          selectedDate = DateTime.fromMillis(Number(event.currentTarget.id.split("D")[1]));
          displayedDate = displayedDate.set({year: selectedDate.year, month: selectedDate.month, day: (selectedDate.day-(daysPerSlide - 1))});
          renderCalendar(mappedDatesToShow);
        }
      });
    }

    if (!!document.querySelector(".slide-2")) {
      document.querySelector(".slide-2").addEventListener("click", (event) => {
        if (event.currentTarget.id !==  "") {
          selectedDate = DateTime.fromMillis(Number(event.currentTarget.id.split("D")[1]));
          displayedDate = displayedDate.set({year: selectedDate.year, month: selectedDate.month, day: (selectedDate.day-(daysPerSlide - 1))});
          renderCalendar(mappedDatesToShow);
        }
      });
    }
  };

  document.querySelector(".prev").addEventListener("click", () => {
    firstDayOfWeekDate = firstDayOfWeekDate.minus({days: daysPerSlide});
    displayedDate = displayedDate.set({year: firstDayOfWeekDate.year, month: firstDayOfWeekDate.month, day: (firstDayOfWeekDate.day-1)});
    renderCalendar(mappedDatesToShow);
  });

  document.querySelector(".next").addEventListener("click", () => {
    firstDayOfWeekDate = firstDayOfWeekDate.plus({days: daysPerSlide});
    displayedDate = displayedDate.set({year: firstDayOfWeekDate.year, month: firstDayOfWeekDate.month, day: (firstDayOfWeekDate.day-1)});
    renderCalendar(mappedDatesToShow);
  });
  
  renderCalendar(mappedDatesToShow);
};
