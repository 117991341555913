window.enterToSearchLocation = enterToSearchLocation;

function enterToSearchLocation(event) {
    const searchButton = document.getElementById("location-form-search");
    if (event.key == "Enter") {
        event.preventDefault();
        searchButton.click();
    }
}

window.enterToSearchLocationMobile = enterToSearchLocationMobile;

function enterToSearchLocationMobile(event) {
    const searchButton = document.getElementById("location-form-search-mobile");
    if (event.key == "Enter") {
        event.preventDefault();
        searchButton.click();
    }
}