import { Controller } from "stimulus";

export default class extends Controller {
  static values = {
    target: String,
    current: String,
    wait: Number,
  };
  connect() {
    if (this.currentValue.startsWith(this.targetValue)) {
      this.reload = setTimeout(
        () => Turbolinks.visit(location),
        this.waitValue
      );
    }
  }
  disconnect() {
    clearTimeout(this.reload);
  }
}
