window.activateDatePicker = activateDatePicker;

function activateDatePicker(input) {
    var dateFormat = { weekday: undefined, year: 'numeric', month: 'long', day: 'numeric' };
    datepicker(input, {
        formatter: (input, date, instance) => {
            const value = date.toLocaleDateString("en-US", dateFormat);
            input.value = value;
        }
    });
}