import { Controller } from "stimulus";
import 'js-autocomplete/auto-complete.css';
import autocomplete from 'js-autocomplete';

export default class extends Controller {
    static values = { path: String, tab: String };
    static targets = ["field", "input"];

    connect() {
        const tests = JSON.parse(this.fieldTarget.dataset.tests)
        const searchInput = this.inputTarget;
        const path = this.pathValue === "" ? "search" : this.pathValue;
        const locale = window.location.search.match(/locale=([a-z]{2})/g);
        const tab = this.tabValue === "" ? "tests" : this.tabValue;

        if (tests && searchInput) {
            new autocomplete({
                selector: searchInput,
                minChars: 1,
                source: function(term, suggest) {
                    term = term.toLowerCase();
                    const choices = tests;
                    const matches = [];
                    for (let i = 0; i < choices.length; i++)
                        if (~choices[i].toLowerCase().indexOf(term)) matches.push(choices[i]);
                    suggest(matches.slice(0, 5));
                },
                onSelect: function(e, term, item) {
                    if (term.includes("*")) {
                        window.location.href = `/${path}?tab=browse&category=383&query=${encodeURIComponent(term.replace('*', ''))}${locale === null ? '' : '&' + locale}`;
                    } else {
                        window.location.href = `/${path}?tab=${tab}&query=${encodeURIComponent(term)}${locale === null ? '' : '&' + locale}`;
                    }
                }
            });
        }
    }
}
