window.askLocation = function askLocation() {
  if (navigator.geolocation) {
    navigator.geolocation.getCurrentPosition(pushPosition);
  }
};

function pushPosition(position) {
  fetch("/location/check").then((res) => {
    if ((res.status != 200)) {
      const lat = position.coords.latitude;
      const long = position.coords.longitude;
      const params = new URLSearchParams();
      params.append("location[]", lat);
      params.append("location[]", long);
      let url =
        location.protocol +
        "//" +
        location.host +
        "/location/set" +
        "?" +
        params.toString();
      fetch(url);
    }
  });
}
