import { Controller } from "stimulus";

export default class extends Controller {
    static targets = ["modal"];

    openModal() {
        let modalController = this.application.getControllerForElementAndIdentifier(
            this.modalTarget,
            "modal"
        );
        modalController.open(this.modalTarget.parentElement);

        document.querySelector('.autocomplete-results').innerHTML = '';
        document.querySelector('.autocomplete-results').style.display = 'none';

        window.initMap(document.getElementById('location').value);
    }

    resize() {
        google.maps.event.trigger(map, "resize");
    }
}