window.cartToggle = cartToggle;

function cartToggle() {
    let cart = document.querySelector("div#cart");
    let cartBadge = document.querySelector("p#cart-badge");
    let cartIcon = document.querySelector("i#cart-btn-icon");
    let cartStatusInput = document.querySelector('input#cart_status');
    if (!!cart) {
        if (cart.classList.contains("hidden")) {
            if (!!cartStatusInput) cartStatusInput.value = 'open';
            localStorage.setItem('cartStatus', 'open');
            if (typeof zE != 'undefined') zE('messenger:set', 'cookies', false);
            cart.classList.remove("pop-in");
            cart.classList.add("pop-out");
        } else {
            if (!!cartStatusInput) cartStatusInput.value = '';
            localStorage.setItem('cartStatus', 'hidden');
            if (typeof zE != 'undefined') zE('messenger:set', 'cookies', true);
            cart.classList.add('pop-in');
            cart.classList.remove("pop-out");
        }
        cart.classList.toggle("hidden");

        cartIcon.classList.toggle("fa-shopping-cart");
        cartIcon.classList.toggle("fa-times");

        if (!!cartBadge) cartBadge.classList.toggle("hidden");
    }
};