import { Controller } from "stimulus";

export default class extends Controller {
    static targets = ["modal"];

    openModal() {
        this.modalTarget.style.display = "grid";
    }

    closeModal() {
        this.modalTarget.style.display = "none";
    }
}