import { Controller } from "stimulus";

export default class extends Controller {
    static targets = ["template", "container"];
    static values = {
        target: String,
        text: String,
    };
    addEntry(e) {
        e.preventDefault();
        const content = this.templateTarget.innerHTML;
        let element = document.createElement("div");
        element.innerHTML = content;
        this.containerTarget.append(element);
    }

    removeEntry(e) {
        e.preventDefault();
        if (document.querySelectorAll(`.${this.targetValue}`).length > 0) {
            e.target.closest(`.${this.targetValue}`).remove();
        } else {
            document.querySelector(`.${this.textValue}`).value = ''
        }
    }
}