window.typeWriter = function typeWriter(element, text, speed, characterIndex = 0) {
    if (element.innerHTML.length > text.length || !text.includes(element.innerHTML)) {
        element.innerHTML = '';
        return;
    }
    if (characterIndex < text.length) {
        if (characterIndex + 1 == text.length && element.innerHTML.length + 1 !== text.length) {
            element.innerHTML = '';
            return;
        }
        element.innerHTML += text.charAt(characterIndex);
        characterIndex++;
        setTimeout(() => { typeWriter(element, text, speed, characterIndex) }, speed);
    }
}