/**
 * @param {Date} start Date of starting element
 * @param {Date} end Date of ending element
 * */
window.getDaysArray = function (start, end) {
  for (
    var arr = [], dt = new Date(start);
    dt <= end;
    dt.setDate(dt.getDate() + 1)
  ) {
    arr.push(new Date(dt).toISOString().slice(0, -14));
  }
  return arr;
};
