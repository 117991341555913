import { Controller } from "stimulus";

export default class extends Controller {
    static targets = ["hovercard"];
    static values = { url: String };

    show() {
        if (this.hasHovercardTarget) {
            this.hovercardTarget.classList.remove("hidden");
        }
    }

    hide() {
        if (this.hasHovercardTarget) {
            this.hovercardTarget.classList.add("hidden");
        }
    }

    disconnect() {
        if (this.hasHovercardTarget) {
            this.hovercardTarget.remove();
        }
    }
}