import { Controller } from "stimulus";

export default class extends Controller {
    static targets = ["dropdown"];

    toggle() {
        if (this.dropdownTarget.style.display == "block") {
            this.dropdownTarget.style.display = "none";
            this.dropdownTarget.classList.toggle("fade-in");
            this.dropdownTarget.classList.toggle("fade-out");
        } else {
            this.dropdownTarget.style.display = "block";
            this.dropdownTarget.classList.toggle("fade-in");
            this.dropdownTarget.classList.toggle("fade-out");
        }
    }
}