import { Controller } from "stimulus";
import { Calendar } from "@fullcalendar/core";
import timeGridPlugin from "@fullcalendar/timegrid";
export default class extends Controller {
    static targets = ["table"];
    static values = {
        events: Array,
        url: String,
    };
    connect() {
        this.colors = ['fc-orange', 'fc-yellow', 'fc-blue', 'fc-violet', 'fc-pink'];
        this.events = [];
        for (let element of this.eventsValue) {
            this.events.push(element);
        }
        this.events = this.events.map((x) => {
            if (x.slot == true) {
                return {
                    start: x.start,
                    end: x.end,
                    title: '  5am-7am (' + `${x.interval_0}` + ') 7am-9am (' + `${x.interval_1}` + ') 9am-11am (' + `${x.interval_2}` + ') 11am-1pm (' + `${x.interval_3}` + ') 1pm-3pm (' + `${x.interval_4}`,
                    color: `hsla(226, 75%, 60%, 0.12)`,
                    className: `fc-gray`,
                    allDay: true,
                    html: true,
                }
            } else if (x.medtech) {
                return {
                    start: x.start,
                    end: x.end,
                    title: `${x.title} ${x.subtitle} (${x.medtech})`,
                    url: x.url,
                    color: `hsla(226, 75%, 60%, 0.12)`,
                    editable: true,
                    className: `${this.eventColor(x.color_id)}`,
                }
            } else {
                return {
                    start: x.start,
                    end: x.end,
                    url: `${this.urlValue}?datetime[]=${x.start}&datetime[]=${x.end}`,
                    title: `${x.title} ${x.subtitle}`,
                    color: `hsla(226, 75%, 60%, 0.12)`,
                    editable: true,
                    className: `fc-green`,
                }
            };
        });
        const calendar = new Calendar(this.tableTarget, {
            plugins: [timeGridPlugin],
            initialView: "timeGridWeek",
            headerToolbar: {
                left: "prev,next today",
                center: "title",
                right: "timeGridWeek,timeGridDay",
            },
            events: this.events,
            allDaySlot: true,
            allDayText: 'Available Slots',
        });
        calendar.render();
        // console.log(this.color)
    }
    eventColor(id) {
        let index = id % this.colors.length;
        return this.colors[index];
    }
}