window.onboardingIndex = 0;

window.onboardingSelect = function onboardingSelect(element, letter, ID) {
  let input = document.getElementById('response');
  let options = document.querySelectorAll(`#${ID} .option`);
  let cta = document.querySelector(`#${ID}-actions .cta`);
  let back = document.querySelector(`#${ID}-actions .escape`);

  if (!!input) {
    if (element.className.includes('active')) {
      input.value = window.setCharacterAt(input.value, window.onboardingIndex, '');

      for (let i = 0; i < options.length; i++) {
        options[i].classList.remove('active');
      }

      element.classList.remove('active');
      cta.setAttribute('disabled', true);
      back.classList.remove('active');
    } else {
      input.value = window.setCharacterAt(input.value, window.onboardingIndex, letter);

      for (let i = 0; i < options.length; i++) {
        options[i].classList.remove('active');
      }

      element.classList.add('active');
      if (!!cta) cta.removeAttribute('disabled');
      if (!!back) back.classList.add('active');
    }
  }
}

window.onboardingNext = function onboardingNext() {
  window.onboardingIndex += 1;
  let input = document.getElementById('response');
  let steps = document.getElementsByClassName('onboarding-step');
  let nextStep = document.getElementById(`s${input.value.slice(0, onboardingIndex)}`);

  for (let i = 0; i < steps.length; i++) {
    steps[i].classList.remove('active');
  }

  nextStep.classList.add('active');
}

window.onboardingBack = function onboardingBack(element) {
  window.onboardingIndex -= 1;
  let input = document.getElementById('response');
  let steps = document.getElementsByClassName('onboarding-step');
  let previousStep;
  if (element.className.includes('active')) {
    previousStep = document.getElementById(`s${input.value.slice(0, -2)}`);
  } else {
    previousStep = document.getElementById(`s${input.value.slice(0, -1)}`);
  }

  for (let i = 0; i < steps.length; i++) {
    steps[i].classList.remove('active');
  }

  previousStep.classList.add('active');
}

window.setCharacterAt = function setCharacterAt(string, index, character) {
  if ((index === string.length - 1) && character === "") {
    return string.slice(0, -1);
  } else if (index === 0 && character === "A") {
    if (string.length > 1) {
      let options = document.querySelectorAll(`#sB .option`);
      let cta = document.querySelector(`#sB-actions .cta`);
      let back = document.querySelector(`#sB-actions .escape`);
      
      for (let i = 0; i < options.length; i++) {
        options[i].classList.remove('active');
      }

      cta.setAttribute('disabled', true);
      back.classList.remove('active');
    }
    return "A";
  }

  string = string.split('');
  string[index] = character;
  string = string.join('');

  return string;
}