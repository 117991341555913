import Rails from "@rails/ujs";
import Turbolinks from "turbolinks";
import * as ActiveStorage from "@rails/activestorage";
import "channels";
import "particles.js/particles";

Rails.start();
Turbolinks.start();
ActiveStorage.start();

// Stylesheets
import ("styles/application.css");

// JQuery
global.$ = require("jquery");
require("jquery");
require("jquery-ui");

// Date Picker
import datepicker from 'js-datepicker'
window.datepicker = datepicker

import { Loader } from "@googlemaps/js-api-loader"; // Javascript Maps API

// Copy To Cliboard
import copy from 'copy-to-clipboard'
window.copy = copy

import 'easy-autocomplete/dist/jquery.easy-autocomplete'; // Easy-autocomplete plugin

import 'luxon';

// Stimulus
import "controllers";
require("../helpers/");
require("trix")
require("@rails/actiontext")