import { Controller } from "stimulus";



export default class extends Controller {

  

  connect(){
    
    //easy-autocomplete
    let options = {
      url: function() {
        return "/administrator/customers/get_list";
      },
    
      getValue: function(element) {
        return element.email;
      },
    
      ajaxSettings: {
        dataType: "json",
        method: "GET"
      },
      list: {
        match: {
          enabled: true
        },
        onChooseEvent: function() {

          let id = $("#booking_customer_email").getSelectedItemData().id;
          let first_name = $("#booking_customer_email").getSelectedItemData().first_name;
          let last_name = $("#booking_customer_email").getSelectedItemData().last_name;
          let address = $("#booking_customer_email").getSelectedItemData().address;

          $("#booking_customer_id").val(id).trigger("change");
          $("#booking_customer_first_name").val(first_name).trigger("change");
          $("#booking_customer_last_name").val(last_name).trigger("change");
          $("#booking_exact_address").val(address).trigger("change");

          $.ajax({
            type: "GET",
            url: "/administrator/customers/customer_info_complete",
            data:{"customer_id":document.getElementById("booking_customer_id").value},
            dataType: "json",
            success: function(res){
              if(res.complete === false){
                createNotice("Customer data is still incomplete, redirecting to customer page");
                setTimeout(function () {
                  window.location.href = '/administrator/customers/'+document.getElementById("booking_customer_id").value+'/edit';
                }, 3000);
              }
            }
          });

          let cart = addTestsToCart();
          triggerPriceCalculator(
            cart,
            document.getElementById("booking_discount").value,
            document.getElementById("booking_customer_id").value,
            document.getElementById('booking_service_area').options[document.getElementById('booking_service_area').selectedIndex].text
          );

        }
      },
      requestDelay: 500
    };
    $("#booking_customer_email").easyAutocomplete(options);


    //google maps
    let location_latitude = document.getElementById("location-latitude").value;
    let location_longitude = document.getElementById("location-longitude").value;
    if(location_latitude === "" || location_longitude === ""){
      initMap(document.getElementById("location").value);
    }else{
      initMap(document.getElementById("location").value,parseFloat(location_latitude),parseFloat(location_longitude));
    }

  }

  applyDiscountCode(){
    let cart = addTestsToCart();
    triggerPriceCalculator(
      cart,
      document.getElementById("booking_discount").value,
      document.getElementById("booking_customer_id").value,
      document.getElementById('booking_service_area').options[document.getElementById('booking_service_area').selectedIndex].text
    );
  }

  applyServiceArea(){
    let cart = addTestsToCart();
    triggerPriceCalculator(
      cart,
      document.getElementById("booking_discount").value,
      document.getElementById("booking_customer_id").value,
      document.getElementById('booking_service_area').options[document.getElementById('booking_service_area').selectedIndex].text
    );
  }

  

  
    
}