window.testScrollLeft = testScrollLeft;

function testScrollLeft() {
    let scrollUnit = 174;

    let leftArrow = document.querySelector('#tests-arrows .left-arrow');
    let rightArrow = document.querySelector('#tests-arrows .right-arrow');

    let testContainer = document.getElementById('tests-container');
    let scrollProgress = testContainer.scrollLeft;

    testContainer.scrollBy(-scrollUnit, 0);
    rightArrow.style.display = "block";

    if (scrollProgress - scrollUnit < 42) {
        testContainer.scrollLeft = 0;
        if (!!leftArrow) {
            leftArrow.style.display = "none";
        }
    }
}

window.testScrollRight = testScrollRight;

function testScrollRight() {
    let scrollUnit = 174;

    let leftArrow = document.querySelector('#tests-arrows .left-arrow');
    let rightArrow = document.querySelector('#tests-arrows .right-arrow');

    let testContainer = document.getElementById('tests-container');

    let offsetWidth = testContainer.offsetWidth;
    let scrollWidth = testContainer.scrollWidth;
    let scrollProgress = testContainer.scrollLeft;

    testContainer.scrollBy(scrollUnit, 0);
    leftArrow.style.display = "block";

    if (offsetWidth + scrollProgress + scrollUnit > scrollWidth - 42) {
        testContainer.scrollLeft = scrollWidth;
        if (!!rightArrow) {
            rightArrow.style.display = "none";
        }
    }
}

window.testScroll = testScroll;

function testScroll() {
    let leftArrow = document.querySelector('#tests-arrows .left-arrow');
    let rightArrow = document.querySelector('#tests-arrows .right-arrow');

    let testContainer = document.getElementById('tests-container');

    let offsetWidth = testContainer.offsetWidth;
    let scrollWidth = testContainer.scrollWidth;
    let scrollProgress = testContainer.scrollLeft;

    if (!!leftArrow && scrollProgress === 0) {
        leftArrow.style.display = "none";
    } else {
        leftArrow.style.display = "block";
    }

    if (!!rightArrow && offsetWidth + scrollProgress === scrollWidth) {
        rightArrow.style.display = "none";
    } else {
        rightArrow.style.display = "block";
    }
}