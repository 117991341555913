import { Controller } from "stimulus";
import { DateTime, Settings } from "luxon";

/* Luxon Settings */
Settings.defaultZone = "America/New_York";
DateTime.now().setZone("default"); // uses the default time
// DateTime.now().setZone("system") // uses the user's system time
export default class extends Controller {
  static targets = ["date", "time"];
  static values = {
    timeTotal: Number,
    url: String,
    controller: String,
    controllerAction: String,
  };

  connect() {
    fetch(`${this.urlValue}`)
      .then((res) => res.json())
      .then((data) => {
        this.availableDates = data.dates;
        this.availabilityHash = data.availability;
        this.availabilitySlotHash = data.availability_slot;
        this.timeBlocks = Math.ceil(this.timeTotalValue / 30.0);
        this.timeTotalHrs = (this.timeBlocks * 30) / 60.0;
        this.dates = this.availableDates.map((date) => DateTime.fromISO(date));
        window.createCalendar(
          this.dates,
          DateTime.fromISO(this.dateTarget.value)
        );
        this.populate();
      });
  }

  populate() {
    this.emptyContainer();
    this.createTimeBlocks(this.dateTarget.value);
  }

  createTimeBlocks(date) {
    let [schedule, blocks] = this.getSchedule(date);
    const sortedBlocks = Object.keys(blocks)
      .map((e) => (e = parseFloat(e)))
      .sort((a, b) => a - b);

    // Set defaults for start time and medtechs
    $("[id$=start_time]").val(sortedBlocks[0]);
    $("[id$=medtechs]").val(blocks[sortedBlocks[0]]);
    if (
      this.getTotalSlot(date, 0) == 0 &&
      this.getTotalSlot(date, 1) == 0 &&
      this.getTotalSlot(date, 2) == 0 &&
      this.getTotalSlot(date, 3) == 0 &&
      this.getTotalSlot(date, 4) == 0
    ) {
      let text = document.createElement("p");
      text.classList.add("no-timeslots");
      let formattedDate = new Date(date);
      formattedDate = formattedDate.toLocaleDateString("en-US", {
        weekday: "long",
        year: "numeric",
        month: "long",
        day: "numeric",
      });
      formattedDate = `${formattedDate.split(", ")[0]} — ${
        formattedDate.split(", ")[1]
      }, ${formattedDate.split(", ")[2]}`;
      text.innerHTML = `No available timeslots for ${formattedDate}.`; //Added "T00:00:00-05:00" For default 12am est, to be non dependent on user device timezone
      $("#time-blocks-container").append(text);
    } else {
      let timeBlocks = document.createElement("div");
      timeBlocks.id = "time-blocks";
      $("#time-blocks-container").append(timeBlocks);
    }

    // Create time blocks
    let renderedBlocks = [];
    sortedBlocks.forEach((element, index) => {
      let radioButton = document.createElement("div");
      if (index == 0) {
        radioButton.classList.toggle("selected");
      }
      let ID = `radio${element}`;
      blocks[element].forEach((item) => {
        ID += `M${item}`;
      });
      let blockLabel = "";
      let value =
        localStorage.getItem("withMobileFee") == "false" ? "$0" : "$130";
      let feeElement =
        this.controllerValue == "typeform" ||
        this.controllerActionValue == "validate"
          ? ""
          : `<div class="time-block-fee">${value}</div>`; //remove fee if rendered on typeform or customer booking validation page
      switch (parseFloat(element)) {
        case 5:
          blockLabel =
            `<div class="time-block-schedule">5<span>AM</span> - 7<span>AM</span></div>` +
            feeElement +
            `<div class="time-block-slot">` +
            this.getTotalSlot(date, 0) +
            " slots remaining</div>";
          break;
        case 7:
          blockLabel =
            `<div class="time-block-schedule">7<span>AM</span> - 9<span>AM</span></div>` +
            feeElement +
            `<div class="time-block-slot">` +
            this.getTotalSlot(date, 1) +
            " slots remaining</div>";
          break;
        case 9:
          blockLabel =
            `<div class="time-block-schedule">9<span>AM</span> - 11<span>AM</span></div>` +
            feeElement +
            `<div class="time-block-slot">` +
            this.getTotalSlot(date, 2) +
            " slots remaining</div>";
          break;
        case 11:
          blockLabel =
            `<div class="time-block-schedule">11<span>AM</span> - 1<span>PM</span></div>` +
            feeElement +
            `<div class="time-block-slot">` +
            this.getTotalSlot(date, 3) +
            " slots remaining</div>";
          break;
        case 13:
          blockLabel =
            `<div class="time-block-schedule">1<span>PM</span> - 3<span>PM</span></div>` +
            feeElement +
            `<div class="time-block-slot">` +
            this.getTotalSlot(date, 4) +
            " slots remaining</div>";
          break;
      }
      if (!renderedBlocks.includes(blockLabel)) {
        radioButton.innerHTML = blockLabel;
        radioButton.id = ID;
        radioButton.classList.add("time-block", "card", "dark-shadow");
        $("#time-blocks").append(radioButton);
        renderedBlocks.push(blockLabel);
      }
    });

    const changeTime = this.changeTime;
    $(".time-block").click((e) => {
      changeTime(this, e);
    });
  }

  getTotalSlot(date, interval) {
    let total = 0;
    this.availabilityHash.forEach((element) => {
      if (element.availability_slot[date]) {
        total += element.availability_slot[date][interval];
      }
    });
    return total;
  }

  getSchedule(date) {
    let schedule = {};
    schedule = this.availabilityHash.map((element) => {
      let id = element.id;
      let schedule = [];

      if (element.availability_slot[date]) {
        element.availability_slot[date].forEach((i) => schedule.push(i));
      }

      let obj = { id: id, schedule: schedule };
      return obj;
    });

    // Separate schedule into chunks
    schedule?.forEach((element) => {
      let chunks = [];
      while (element.schedule?.length > 0) {
        let chunk = element.schedule?.splice(0, 5);
        chunks.push(chunk);
      }
      element.schedule = chunks;
    });
    // Get blocks to display
    let blocks = {};
    schedule?.forEach((e) => {
      if (e.schedule.length != 0 && e.schedule[0][0] > 0) {
        if (blocks[5]) {
          blocks[5].push(e.id);
        } else {
          blocks[5] = [e.id];
        }
      }
      if (e.schedule.length != 0 && e.schedule[0][1] > 0) {
        if (blocks[7]) {
          blocks[7].push(e.id);
        } else {
          blocks[7] = [e.id];
        }
      }
      if (e.schedule.length != 0 && e.schedule[0][2] > 0) {
        if (blocks[9]) {
          blocks[9].push(e.id);
        } else {
          blocks[9] = [e.id];
        }
      }
      if (e.schedule.length != 0 && e.schedule[0][3] > 0) {
        if (blocks[11]) {
          blocks[11].push(e.id);
        } else {
          blocks[11] = [e.id];
        }
      }
      if (e.schedule.length != 0 && e.schedule[0][4] > 0) {
        if (blocks[13]) {
          blocks[13].push(e.id);
        } else {
          blocks[13] = [e.id];
        }
      }
    });

    return [schedule, blocks];
  }

  emptyContainer() {
    $("#time-blocks-container").empty();
  }

  formatAmPm(time) {
    let ampm = time >= 12 ? "pm" : "am";
    time = time % 12;
    return time + ":00 " + ampm;
  }

  changeTime(obj, e) {
    e.preventDefault();
    $("#time-blocks-container .time-block").removeClass("selected");
    let digits = e.currentTarget.id.split("radio").join("").split("M");
    e.currentTarget.classList.add("selected");
    const time = parseFloat(digits[0]);
    const ids = digits.splice(1, digits.length).map((e) => (e = parseInt(e)));
    $("[id$=start_time]").val(time);
    $("[id$=medtechs]").val(ids);
  }

  changeDate(e) {
    let day;
    if (e.target.id !== "") {
      day = e.target;
    } else if (e.target.parentElement.id !== "") {
      day = e.target.parentElement;
    }

    if (!!day) {
      // USE THIS CODE TO HIGHLIGHT SELECTED/ACTIVE DAY IF DISPLAYED DATES WILL NOT CHANGE/SLIDE ON SELECT
      //$(".day.active").removeClass("active");
      //day.classList.add("active");

      const date = DateTime.fromMillis(parseInt(day.id.split("D")[1]));
      this.dateTarget.value = date.toFormat("yyyy-MM-dd");
      this.populate();
    }
  }
}
