//calculate the total number of checked boxes
window.countCheckedBoxes = function countCheckedBoxes(form) {

  checkedtotal = 0;
  form.querySelectorAll("input[type='checkbox']:checked").forEach(checked_box => {
    checkedtotal += 1;
  });
  return checkedtotal;
  
}

window.addTestsToCart = function addTestsToCart(){
  let cart = [];
  let checkboxes = document.querySelectorAll('*[name^="product"]');
  for (var i = 0; i < checkboxes.length; i++) {
    if (checkboxes[i].checked){
      let test_id = checkboxes[i].value;
      if( test_id.includes("package_") ){
        cart["Package#"+test_id.replace('package_','')] = parseInt(document.querySelector("#product_quantity_"+test_id).value);
      }else{
        cart["Test#"+test_id.replace('test_','')] = parseInt(document.querySelector("#product_quantity_"+test_id).value);
      }
    }
  }
  return cart;
}


window.triggerPriceCalculator = function triggerPriceCalculator(cart,code="",customer="",service_area=""){
  cart_stringified = JSON.stringify({...cart});

  if(Object.keys(cart).length > 0){
    $.ajax({
      type: "GET",
      url: "/administrator/bookings/get_total",
      data:{"cart":cart_stringified,"code":code,"customer":customer,"service_area":service_area},
      dataType: "json",
      success: function(res){
        document.getElementById("booking-subtotal").textContent = res.subtotal;
        document.getElementById("booking-discount").textContent = res.total_discount;
        document.getElementById("booking-service-fee").textContent = res.service_fee;
        document.getElementById("booking-total-price").textContent = res.cart_price;
        
      }
    });
  }else{
    document.getElementById("booking-subtotal").textContent = "0.00";
    document.getElementById("booking-discount").textContent = "0.00";
    document.getElementById("booking-total-price").textContent = "0.00";
  }
  
}
