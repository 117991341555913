import { Controller } from "stimulus";

export default class extends Controller {
    static targets = ["text"];
    static values = {
        texts: Array
    }

    currentTextIndex = 0;
    typeWriterSpeed = 30;

    connect() {
        this.textTarget.innerHTML = '';
        window.typeWriter(this.textTarget, this.currentText(), this.typeWriterSpeed);

        setInterval(() => {
            if (this.currentTextIndex + 1 < this.textsValue.length) {
                this.currentTextIndex += 1;
            } else {
                this.currentTextIndex = 0;
            }

            this.textTarget.innerHTML = '';
            window.typeWriter(this.textTarget, this.currentText(), this.typeWriterSpeed);
        }, 5000);
    }

    currentText() {
        return this.textsValue[this.currentTextIndex];
    }
}